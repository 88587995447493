$base: #0f1923;
$white: #ece8e1;
$gold: #794c00;
$green: #46ffa9;
$light-green: #9affd0;
$height: 55px;
$transition: 0.3s ease-out all;

@mixin common-styling {
  width: 160px;
  max-width: 160px;
  height: $height;
  padding: 8px;
  font-size: 1.2rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;

  &:after,
  &:before {
    content: '';
    width: 1px;
    position: absolute;
    height: 8px;
    background: $base;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    right: 0;
    left: initial;
  }

  .valorant-text {
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
    left: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: 5px;

    .valorant-base {
      box-sizing: border-box;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      left: 0;
      border: 1px solid #cda68c;

      &:before {
        content: '';
        width: 2px;
        height: 2px;
        left: -1px;
        top: -1px;
        background: $base;
        position: absolute;
        transition: $transition;
      }
    }

    .valorant-bg {
      left: -5%;
      position: absolute;
      background: #aa4586;
      width: 0;
      height: 100%;
      z-index: 3;
      transition: $transition;
      transform: skewX(-10deg);
    }

    .valorant-label {
      z-index: 4;
      font-weight: 600;
      letter-spacing: 5px;

      &:after {
        content: '';
        width: 4px;
        height: 4px;
        right: 0;
        bottom: 0;
        background: $base;
        transition: $transition;
        z-index: 5;
      }
    }
  }

  &:hover {
    color: white;
    text-shadow: 0px 0px 0px rgb(255, 186, 24), 0px 0px 0px rgb(255, 186, 24),
      0px 0px 16px rgb(255, 186, 24);
    .valorant-bg {
      width: 110%;
    }
    .valorant-label:after {
      background: $white;
    }
  }
}

.valorant-container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-flow: column;

  .valorant-link {
    @include common-styling;
    color: $green;
    box-shadow: 0 0 0 1px inset #a48672;
    &.white:hover > .valorant-text {
      color: $white;
    }
    &.white > .valorant-text {
      background: $white;
      color: $base;
    }
    &.transparent:hover > .valorant-text {
      color: $white;
    }
    &.transparent > .valorant-text {
      background: $base;
      color: $white;
    }
    &.small {
      width: 100px;
      max-width: 100px;
    }
    &.large {
      width: 250px;
      max-width: 250px;
    }
    &.xl {
      width: 300px;
      max-width: 300px;
    }
  }

  .disabled {
    @include common-styling;
    color: gray;
    box-shadow: 0 0 0 1px inset gray;

    .valorant-text {
      .valorant-base {
        border: 1px solid gray;
      }
    }

    &.white:hover > .valorant-text {
      color: $white;
    }
    &.white > .valorant-text {
      background: $white;
      color: $base;
    }
    &.transparent:hover > .valorant-text {
      color: $white;
    }
    &.transparent > .valorant-text {
      background: $base;
      color: $white;
    }
  }
}
