.lock-bar {
  display: flex;
  border: 1px solid #242424;
  height: 32px;
}

.lock-bar-right {
  flex-grow: 1;
  width: 200px;
}

.lock-bar-right-fill {
  background-color: #d37a2d;
  height: 100%;
  transition: width ease-out 1s;
}
