/* Header */

.header {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;
  letter-spacing: 0.25em;
}

.header-left {
  flex-basis: 140px;
  flex-grow: 1;
}
.header-center {
  flex-grow: 8;
  margin-top: -20px;
}
.header-right {
  flex-basis: 140px;
  flex-grow: 1;
}

/* Question number, category, and par */

.question-count {
  text-align: center;
  letter-spacing: 0.25em;
}

.question-metadata {
  /* position: relative; */
  height: 60px;
  margin-bottom: 10px;
  /* margin-top: -45px; */
}

.middle {
  font-size: 22px;
}

.fade-in-fast {
  opacity: 1;
}

.category,
.par {
  display: flex;
  width: 100%;
  letter-spacing: 0.25em;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: all 1s ease-in-out;
}

.shift-down {
  transform: translate(-50%, 0%);
}

.par {
  top: 50%;
}

.par-center {
  text-align: center;
  width: 100%;
}

.category-left,
.category-right,
.par-left,
.par-right {
  width: 100%;
}

.category-left,
.par-left {
  text-align: right;
}

/* Player profile, score, and seconds */

.profile-pic-cont {
  display: flex;
  justify-content: center;
}

.score {
  font-size: 18px;
  font-weight: 600;
}

/* Game timer and opponent timer */

.game-timer-grow {
  animation-name: grow;
  animation-duration: 0.25s;
  animation-direction: alternate;
  animation-iteration-count: 2;
}

.opponent-timer {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.25em;
  color: gray;
  margin-top: 10px;
  opacity: 0;
}

.fade-in {
  animation: fadein 2s;
  opacity: 1;
}

.fade-out {
  animation: fadeout 2s;
  opacity: 0;
}

/* animations */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

/* Opponent penalty guess reveal */

.opponent-penalty-guess {
  /* position: relative;
  height: 60px;
  margin-bottom: 10px;
  margin-top: -45px; */
  width: 100%;
  text-align: center;
}

@keyframes floatAndFade {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    opacity: 1;
    transform: translateY(-50px);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.float-fade {
  animation-name: floatAndFade;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards; /* Add this line */
}
