.answer-fade-in {
  animation: fadein 2s;
}

.answer-letter-animation {
  background-color: #f1efef;
  animation: fadeToBlue 2s ease-in;
}

.shrink-image {
  animation: shrinkImage 1s ease-in forwards;
  transform-origin: center;
}

/* animations */

@keyframes shrinkImage {
  to {
    transform: translate(-50%, -25%) scale(0.5);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeToBlack {
  0% {
    background-color: #f1efef;
  }
  100% {
    background-color: #3d3c3c;
  }
}

@keyframes fadeToBlue {
  0% {
    background-color: #212121;
  }
  100% {
    background-color: 'primary.dark';
  }
}
