.reveal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
}

.reveal-text {
  span {
    display: inline-block;
    margin: 0 2px;
    color: #ffba18;
    font-family: 'IBM Plex Mono', monospace;
    @for $i from 0 through 14 {
      &:nth-child(#{$i + 1}) {
        filter: blur(0px);
        animation: blur-text 1s (#{$i/13}) + s infinite linear alternate;
      }
    }
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(2px);
  }
}
