/* Player input */

.span-input {
  text-align: center;
  border-bottom: 1px solid black;
  padding: 5px;
  cursor: text;
  font-size: 1.5rem;
  white-space: -moz-pre-space;
}

.span-input:focus {
  outline: none;
  border-bottom: 3px solid #f8ee95;
}

.span-input:empty:before {
  content: attr(data-ph);
  color: #bcbcbc;
  opacity: 0.4;
}

.score-bar-username {
  font-family: 'IBM Plex Mono', monospace;
  text-align: center;
}

/* Keyboard */

/*
  Theme: myTheme1
*/

.simple-keyboard.myTheme1 {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 100vw;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #1c4995;
  color: white;
}

.num-button {
  width: 0px;
  font-size: 12px;
  word-break: normal;
}

.space-button {
  width: 90px;
}

.return-button {
  box-shadow: 2px 2px 4px -1px #cda68c, 0px 0px 5px 0px #cda68c,
    0px 0px 10px 0px #cda68c !important;
}
