.par-logo-dark-league {
  max-width: 160px;
}

.par-logo-dark-league:hover {
  filter: drop-shadow(0px 0px 5px rgba(208, 208, 208, 0.5));
}

.sidenav__logo-normal {
  /* border-radius: 8px; */
  max-width: 168px;
}

.sidenav__logo-xs {
  /* border-radius: 8px; */
  max-width: 80px;
}
