.submitted-by-stamp {
  position: 'absolute';
  bottom: 0;
  left: 25%;
  width: 300px;
  color: #555;
  font-size: 20px;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 1px 3px;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: 'Courier';
  -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
  -webkit-mask-size: 944px 604px;
  // mix-blend-mode: multiply;
  background-color: #212121;
}

.submitted-by-stamp-username {
  color: #ddc6ff;
  border: 0.25rem solid #d9bb6f;
  -webkit-mask-position: 13rem 3rem;
  border-radius: 0;
}

.submitted-by-stamp-small {
  color: #555;
  font-size: 14px;
  font-weight: 700;
  border: 3px solid #555;
  display: inline-block;
  padding: 1px 5px;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: 'Courier';
  -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
  -webkit-mask-size: 944px 604px;
  // mix-blend-mode: multiply;
  background-color: #212121;
}

.submitted-by-stamp-username-small {
  color: #ddc6ff;
  border: 2px solid #d9bb6f;
  -webkit-mask-position: 0rem 1rem;
  border-radius: 0;
}
