.linkify-link {
  color: rgb(0, 122, 243); /* Normal state */
}

.linkify-link:hover {
  color: rgb(0, 38, 255); /* Hover state */
}

.linkify-link:visited {
  color: rgb(200, 7, 200); /* Visited state */
}

.linkify-link:active {
  color: red; /* Active state */
}
