.score-bar {
  display: flex;
  border: 1px solid #242424;
  height: 32px;
}

.score-bar-left {
  background-color: #66bb6a;
  flex-grow: 1;
}

.score-bar-left-fill {
  background-color: #121212;
  height: 100%;
  transition: width ease-out 0.5s;
}

.score-bar-right {
  flex-grow: 1;
  border-left: 1px solid #28cefe;
  background-color: #121212;
}

.score-bar-right-fill {
  background-color: #546e7a;
  height: 100%;
  transition: width ease-out 0.5s;
}

.score-bar-value {
  position: absolute;
  height: 25px;
  font-size: 20px;
  font-weight: 800;
  transform: translate(-50%, 0);
  transition: left ease-out 0.5s;
  font-family: 'IBM Plex Mono', monospace;
  z-index: 999;
  /* background circle */
  /* color: white; */
  /* background-color: #525277;
  border-radius: 50%;
  width: 30px;
  height: 25px; */
}

.score-bar-username {
  position: absolute;
  /* transform: translate(-50%, 0); */
  /* left: 50%; */
  opacity: 0.8;
}
