/* Countdown timer before game starts */

.countdown {
  font-family: 'IBM Plex Mono', monospace;
  height: 150px;
  text-align: center;
  font-size: 78px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
