@font-face {
  font-family: 'astron-boy';
  src: local('astron-boy'), url(./fonts/astron_boy.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'blacksword';
  src: local('blacksword'), url(./fonts/Blacksword.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'schoolbell';
  src: local('schoolbell'), url(./fonts/Schoolbell.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'pixel-perfect';
  src: local('pixel-perfect'),
    url(./fonts/EXEPixelPerfect.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'round-pixel';
  src: local('round-pixel'), url(./fonts/round-pixel.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'fight-this';
  src: local('fight-this'), url(./fonts/FIGHTT3_.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'need-for-speed';
  src: local('need-for-speed'),
    url(./fonts/Need\ for\ Font.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'barbatrick';
  src: local('barbatrick'), url(./fonts/Barbatrick.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'demon-sker';
  src: local('demon-sker'), url(./fonts/DemonSker.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'crosses';
  src: local('crosses'), url(./fonts/Crosses.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'requiem';
  src: local('requiem'), url(./fonts/Reqiuem.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
